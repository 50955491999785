<template>
  <Box>
    <Row>
      <Column>
        <Value> QuickBooks Integration </Value>
      </Column>
    </Row>
  </Box>
</template>
<script>
import { mapGetters } from 'vuex'
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Value from "@/portals/shared/library/value/Value.vue";

export default {
  name: "portal-integrations-qbooks",
  components: {
    Box,Row,Column,
    Value
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
  },
  watch: {
    
  },
  mounted: function() {
    if (this.$router) {
      //
    }
  },
  methods: {
    
  },
}
</script>